import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai';
import { View, Text, StyleSheet, TouchableOpacity, Button, Linking } from 'react-native';

const SocialMedia = ({ iconname, text, link }) => {

    return (
        <TouchableOpacity style={styles.container} onPress={() => Linking.openURL(link)}>
            <View style={{ marginRight: '30px', marginLeft: '10px' }}>{iconname}</View>
            <Text>{text}</Text>
        </TouchableOpacity>
    )
}

const Social = ({navigation}) => {
    
    return (
        <View style={styles.pagewrap}>
            <View style={styles.page}>
                <View style={{ width: '20%', maxWidth: '100px' }}>
                    <Button title='Home' color="black" onPress={() => navigation.navigate('Home')}>

                    </Button>
                </View>
                <View style={styles.wrapper}>
                    <SocialMedia iconname={<AiFillFacebook fontSize="3.5em" />} text={'Like us on Facebook!'} link={'https://www.facebook.com/americanbubbleboy'} />
                    <SocialMedia iconname={<AiFillInstagram fontSize="3.5em"/>} text={'Follow us on Instagram!'} link={'https://www.instagram.com/americanbubbleboy/'} />
                </View>
            </View>
           
        </View>
    )
}

const styles = StyleSheet.create({
    pagewrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '90vh',
        maxHeight: '90vh',
        backgroundColor: 'white'
    },
    page:{
        width: '100%',
        maxWidth: '900px',
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'white'
    },
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '75%',
        height: '15%',
        border: '1px solid black',
        marginBottom: '50px',
        maxWidth: '400px'
    },
})

export default Social;