import { Image, StyleSheet } from "react-native";

const Logo = () => {
    return (
        <Image style={styles.logo} source={require('../assets/mygo2logo.png')} />
    )
}

const styles = StyleSheet.create({
    logo: {
        width: '90%',
        height: '10%',
        resizeMode: 'contain'
    }
})

export default Logo;