import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Linking, Dimensions, Image } from 'react-native';
import { AiOutlineLaptop, AiOutlineStar, AiOutlineTablet } from 'react-icons/ai'
import { BsFillPeopleFill } from 'react-icons/bs';
import { BiStore } from 'react-icons/bi'
import { useNavigation } from '@react-navigation/native';
import { AiOutlineAmazon } from 'react-icons/ai';
import { SiEbay } from 'react-icons/si'
import { TbBrandWalmart } from 'react-icons/tb'


import Logo from './Logo';

const Item = ({ iconname, text, link, subtext, nav, ScreenName }) => {

  const navigation = useNavigation();
  const [orientation, setOrientation] = useState('portrait');

  useEffect(() => {
    Dimensions.addEventListener('change', ({window:{width,height}})=>{
      if (width < height) setOrientation('portrait');
      else setOrientation('landscape')
    })
  }, [])

  return (
    <TouchableOpacity
      onPress={() => nav ? navigation.navigate(ScreenName) : Linking.openURL(link)}
      style={ orientation === 'portrait' ? [ { width: '49%', height: '22%' }, styles.item ] : [ { width: '33%', height: '17%' }, styles.item ] }
    >
      {/* <View style={subtext ? [{marginTop: '10px', color: 'black', paddingBottom: '10px' }] : {color: 'black', paddingBottom: '10px'}}>{iconname}</View> */}
      <Text style={ { color: 'black', fontFamily: 'BarlowCondensed-Black', fontSize: orientation === 'landscape' ? '13px' : '18px', width: '55%'}}>{text}</Text>
      {subtext && (
        <Text style={{ color: '#6b6b69', fontFamily: 'BarlowCondensed-Black', fontSize: '14px' }}>{subtext}</Text>
      )}
    </TouchableOpacity>
  )
}



const Main = () => {
  
  return (
    <View style={styles.wrapper}>
      
      <View style={ styles.container }>
      <Logo style={{ marginTop: '10px' }}/>
        <View style={styles.itemContainer}>
          <TouchableOpacity style={{ width: '50%', height: '10%', display: 'flex', justifyContent: 'space-between' }} onPress={() => Linking.openURL('https://www.americanbubbleboy.com/')}>
              <Image style={{ width: '100%', height: '40%', resizeMode: 'contain'}} source={require('../assets/abblogo.webp')} />
              <Text style={{ textAlign: 'center',color: 'black', fontFamily: 'BarlowCondensed-Black', fontSize: '18px' }}>Order at American Bubble Boy!</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.marketplaceContainer} >
            <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#ff9900' }}>Amazon</Text>
            <View style={styles.iconContainer}>
              <Item  text={'Order from\nAmazon!'} link={'https://www.americanbubbleboy.com/'} subtext={'Coming Soon!'} nav={true} ScreenName={'Marketplaces'}/>
              {/* <Item iconname={<AiOutlineStar fontSize='3.4em'/>} text={'Satisfied?\nRate us!'} link={'https://www.google.com/'} /> */}
            </View>
          </TouchableOpacity>
          
          <TouchableOpacity style={styles.marketplaceContainer}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#E53238' }}>e</Text>
              <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#0064D2' }}>b</Text>
              <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#F5AF02' }}>a</Text>
              <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#86B817' }}>y</Text>
            </View>
            
            <View style={styles.iconContainer}>
              <Item text={'Order from\nebay!'} nav={true} ScreenName={'Marketplaces'} subtext={'Coming Soon!'}/>
              {/* <Item iconname={<AiOutlineStar fontSize='3.4em'/>} text={'Satisfied?\nRate us!'} link={'https://www.google.com/'} /> */}
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={styles.marketplaceContainer}>
            <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#0071CE' }}>Walmart</Text>
            <Item text={'Order from\nWalmart!'} nav={true} ScreenName={'Marketplaces'} subtext={'Coming Soon!'}/>
            {/* <View style={styles.iconContainer}>
              <Item iconname={<AiOutlineStar fontSize='3.4em'/>} text={'Satisfied?\nRate us!'} link={'https://www.google.com/'} />
            </View> */}
          </TouchableOpacity>
        </View>
      </View>
    </View> 
  );
}

const styles = StyleSheet.create({

  marketplaceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-begin',
    alignItems: 'center',
    width: '100%',
    
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
  },

  wrapper: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '95vh',
    maxHeight: '95vh',
    backgroundColor: 'white'
    
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    
  },
  title: {
    fontFamily: 'BarlowCondensed-Black'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '85vh',
    width: '100%',
    maxWidth: '900px',
    maxHeight: '80vh',
    justifyContent: 'center',
    alignItems:'center',
    
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5px',
    fontFamily: 'BarlowCondensed-Black',
  },
  logo: {
    height: '10%'
    , width: '10%'
  }
});

export default Main;