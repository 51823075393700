import React from 'react';
import { BiFontSize } from 'react-icons/bi';
import { Image, StyleSheet, View, Text, Button, Linking } from 'react-native'

const AppScreen = ({ navigation }) => {
    return (
        <View style={styles.pagewrap}>
            <View style={styles.container}>
            <Text style={{ fontSize: 36}}>Whoops!</Text>
            <Text style={{ margin: 10 }}>This isnt quite ready yet. For now, you can purchase at American Bubble Boy!</Text>
            <Button title='Take me there!' onPress={() => Linking.openURL('https://www.americanbubbleboy.com/')}></Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    pagewrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        maxHeight: '100vh',
        backgroundColor: 'white'
    },
    container: {
        display: 'flex',
        width: '50%',
        textAlign: 'center',
        maxWidth: '500px'
    }
})

export default AppScreen;